/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react'
import { Link, navigate } from 'gatsby'
// Components
import {
  Seo,
  HeroSwiper,
  BenefitsSwiper,
  ProductCategory,
  FeaturedProducts,
  Loading,
  ProductCard,
} from '../components'
// Context
import { useStore } from '../context/StoreContext'
import { useAuthContext } from '../context/AuthContext'
import { usePrismic } from '../context/PrismicContext'
// Styles
import {
  Wrapper,
  ShoppingWith,
  Section,
  Title,
  CategoriesGrid,
  ProductsGrid,
  ShopAllButton,
} from '../styles/LandingPage.styles'

// Turn on/off "shop by" features
const hasFeatures = true

const LandingPage = () => {
  const {
    prismicData: { prismicHomepage },
  } = usePrismic()

  const {
    isAmbassador,
    isQAccountVerified,
    isUserAuthenticated,
    qUser,
    isEventSite,
    loading: loadingAuth,
  } = useAuthContext()

  const { landingPageData, isLoadingLandingPage } = useStore()
  const {
    title,
    happy_shopping,
    welcome,
    shopping_with,
    click_here,
    slide_images,
    event_slide_images,
    featured_products,
    shop_by_benefits,
    shop_type,
    shop_all_products,
  } = prismicHomepage

  const productTypes = landingPageData['type']?.children ?? []
  const productFeatured = landingPageData['featured-products'] ?? []

  const uplineUser = qUser?.uplineUser?.displayName ?? 'n/a'
  const ambassadorName = qUser?.displayName ?? ''

  useEffect(() => {
    if (!isQAccountVerified) {
      navigate('/login')
    }
  }, [isQAccountVerified])

  if (isLoadingLandingPage || loadingAuth)
    return (
      <Loading
        loading={true}
        message={happy_shopping[0].text}
        showBg={isEventSite}
      />
    )

  return (
    <>
      <Seo title={title[0].text} />
      <Wrapper>
        {isUserAuthenticated && isAmbassador ? (
          <ShoppingWith
            data-qa={`ambassadorName-${ambassadorName}`}
          >{`${welcome[0].text} ${ambassadorName}`}</ShoppingWith>
        ) : isUserAuthenticated && !isAmbassador ? (
          <ShoppingWith
            data-qa={`uplineName-${uplineUser}`}
          >{`${shopping_with[0].text} ${uplineUser}`}</ShoppingWith>
        ) : (
          <ShoppingWith data-qa="signInButton">
            <Link to="/login">{click_here[0].text}</Link>
          </ShoppingWith>
        )}
        <Section black>
          <HeroSwiper
            data-qa="heroBanner"
            slide_images={isEventSite ? event_slide_images : slide_images}
          />
        </Section>
        {!hasFeatures ? (
          <Section>
            <Title data-qa="featuredProductsTitle">
              {featured_products[0].text}
            </Title>
            <ProductsGrid data-qa="featuredProductsGrid">
              {productFeatured.map(product => (
                <ProductCard key={product.url_key} {...product} />
              ))}
            </ProductsGrid>
          </Section>
        ) : (
          <>
            <Section>
              <Title>{featured_products[0].text}</Title>
              <FeaturedProducts products={productFeatured} />

              <ShopAllButton data-qa="shopAllButton" as={Link} to="/products">
                {shop_all_products[0].text}
              </ShopAllButton>
            </Section>
            {/* <Section black>
              <Title white>{shop_by_benefits[0].text}</Title>
              <BenefitsSwiper />
            </Section> */}
            <Section>
              <Title>{shop_type[0].text}</Title>
            </Section>
            <CategoriesGrid style={{ marginBottom: '40px' }}>
              {productTypes.map(productType => (
                <ProductCategory key={productType.url_key} {...productType} />
              ))}
            </CategoriesGrid>
          </>
        )}
      </Wrapper>
    </>
  )
}

export default LandingPage
